/**
 * @author        chenjie <zlf@beyondin.com>
 * @date          2023-03-21 17:42:12
 * Copyright © YourCompanyName All rights reserved
 */
import {getFooterList} from '@/server/services';
import QRCode from "qrcode";
export default {
    data() {
        return {
            isShow: true,
            footerNav: [{
                icon: 'https://oss.yucongkeji.cn/yucong-home/img/7x24HourService.png',
                name: '7x24小时服务'
            },{
                icon: 'https://oss.yucongkeji.cn/yucong-home/img/Purchase.png',
                name: '自助购买和续费升级'
            },{
                icon: 'https://oss.yucongkeji.cn/yucong-home/img/FlexibleDeployment.png',
                name: '灵活部署'
            },{
                icon: 'https://oss.yucongkeji.cn/yucong-home/img/PersonalTailor.png',
                name: '支持私人订制'
            },{
                icon: 'https://oss.yucongkeji.cn/yucong-home/img/ExclusiveServices.png',
                name: '1对1专属服务'
            }], //
            footerYNav: [{
                h1: '快速入口',
                title1: '用户中心',
                title2: '订单列表',
                title3: '优惠券'
            },{
                h1: '帮助中心'
            },
            {
                h1: '联系方式',
                title1: '联系电话：17767236053',
                title2: 'E-mail：official@yucongkeji.com',
                title3: '公司地址：杭州市余杭区西溪八方城3栋'
            }],
            qrcodeUrl: '', // 小程序二维码
            problemList:[]
        };
    },
    watch:{
        $route:{
            immediate:true,
            handler({path}){

                this.onshow(path);
            }
        }
    },
    mounted() {
        this.onshow(window.location.hash.split('#')[1].split('?')[0]);
        this.getQRcode();
        this.getFooterList();
    },
    methods: {
        openbeian(){
            window.open('https://beian.miit.gov.cn');
        },
        Tofooter(key,type){
            if(type){
                this.$router.push({
                    path:"/services/help",
                    query:{
                        id:key
                    }
                });
                return;
            }
            switch (key) {
            case '订单列表':  this.$router.push('/user-center/order'); break;
            case '用户中心':  this.$router.push('/user-center/info'); break;
            case '优惠券':  this.$router.push('/user-center/Coupon'); break;
            default:  break;
            }
        },
        //获取页脚文章
        async getFooterList(){
            let  { data } = await getFooterList();

            this.problemList = data;
        },

        onshow(path) {
            const routers = ['/login','/Login', '/order', '/agreement/login', '/agreement/function', '/agreement/privacy','/services/help'];

            if (routers.indexOf(path) === -1) {
                this.isShow = true;
            } else {
                this.isShow = false;
            }
        },
        // 小程序二维码
        getQRcode() {
            QRCode.toDataURL('https://17office.yucongkeji.com/index.html?#/knowledgeList/35?id=35', {
                width: 80,
                height: 80
            }, (err, url) => {
                this.qrcodeUrl = url;
            });
        }
    }
};